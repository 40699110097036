import { Box, SxProps, Theme, Typography } from '@mui/material';
import { styles } from './styles';
import { useMemo } from 'react';

export type SectionProps = {
  img: string;
  minImg: string;
  title: string;
  description: string;
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  aspectRatio: string;
  pt?: string;
  variant: 'left' | 'right';
};

export const Section = ({
  img,
  minImg,
  title,
  description,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  variant,
  aspectRatio,
  pt = '0px',
}: SectionProps) => {
  const gridProps = useMemo(
    () =>
      variant === 'left'
        ? {
            title: {
              gridColumnStart: 3,
              gridRowStart: 2,
              gridRowEnd: 3,
              '@media (max-width: 600px)': {
                gridColumnStart: 2,
                gridRowStart: 1,
                gridRowEnd: 3,
                padding: '10px',
                height: '100%',
              },
            },
            description: {
              gridColumnStart: 1,
              gridRowStart: 3,
              gridRowEnd: 6,
              gridColumnEnd: 3,
            },
          }
        : {
            title: {
              gridColumnStart: 1,
              gridRowStart: 2,
              gridRowEnd: 3,
              '@media (max-width: 600px)': {
                gridRowStart: 1,
                gridRowEnd: 3,
                padding: '10px',
                height: '100%',
              },
            },
            description: {
              gridColumnStart: 2,
              gridRowStart: 3,
              gridColumnEnd: 4,
              gridRowEnd: 6,
              '@media (max-width: 600px)': {
                gridColumnStart: 1,
                gridRowStart: 3,
                gridRowEnd: 6,
              },
            },
          },
    [variant]
  );

  return (
    <Box
      sx={{
        ...styles.box,
        gridColumnStart,
        gridColumnEnd,
        gridRowStart,
        backgroundImage: `url(${img})`,
        aspectRatio,
        '@media (max-width: 600px)': {
          backgroundImage: `url(${minImg})`,
          aspectRatio: '1',
          gridTemplateColumns: '1fr 1fr',
        },
      }}
    >
      <Typography
        variant="h3"
        sx={
          {
            ...gridProps.title,
            ...styles.title,
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          } as SxProps<Theme>
        }
        align="center"
      >
        {title.split(' ').join('\n')}
      </Typography>
      <Typography
        sx={
          {
            ...gridProps.description,
            ...styles.description,
            '@media (max-width: 1200px)': {
              paddingTop: pt,
            },
          } as SxProps<Theme>
        }
      >
        {description}
      </Typography>
    </Box>
  );
};

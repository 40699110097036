import { theme } from 'theme';

export const styles = {
  root: {
    width: '100%',
    border: `1px solid ${theme.colors.dark}`,
    borderRadius: '8px',
    padding: '20px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [theme.breakpoints.md]: {
      padding: '18px 16px',
    },

    '@media (max-width: 344px)': {
      padding: '10px 8px', 
    }
  },
  titleBlock: {
    justifyContent: 'center',
  },
  description: {
    textAlign: 'center',
    color: theme.colors.grey,
    fontSize: theme.fontSizes.t2,
  },
  title: {
    textAlign: 'center',
    color: theme.colors.darkBlue,
    fontSize: theme.fontSizes.h2,
  },
  mainImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  labels: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
  },
  technologies: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',

    [theme.breakpoints.md]: {
      gap: '8px',
    },
  },
  label: {
    padding: '8px 16px',
    borderRadius: '50px',
    fontSize: '14px',
  }
};

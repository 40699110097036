import { theme } from "theme";

export const styles = {
    title: {
        fontSize: 'clamp(2rem, 5vw, 6rem)',
        marginBottom: '60px',
    },
    computer: {
        width: 'calc(50dvw - 60px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
        [theme.breakpoints.md]: {
            width: 'calc(50dvw - 15px)'
        },

        'img': {
            width: '100%'
        },

        [theme.breakpoints.sm]: {
            display: 'none'
        }
    },
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.sm]: {
            gridTemplateColumns: '1fr',
        }
    },
    line: {
        position: 'absolute',
        height: '140%',
        borderLeft: '2px solid black',
        zIndex: '-1'
    },
    mainWrapper: { 
        position: 'relative',
        [theme.breakpoints.sm]: {
            padding: '0 20px',
        } 
    }
};

import styled from 'styled-components';
import { theme } from 'theme';

export const VerticalLineStyled = styled.div`
  width: calc(50dvw - 60px);
  border-left: 2px solid ${theme.colors.dark};
  padding: 80px;
  padding-top: 0;
  position: relative;
  box-sizing: border-box;

  ${[theme.breakpoints.md]} {
    width: calc(50dvw - 15px);
    padding: 40px;

    img {
      height: 30px;
    }
  }

  ${[theme.breakpoints.sm]} {
    border-bottom: none;
    border-bottom-left-radius: 0;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
`;

export const VerticalLine = ({
  children,
  img,
}: {
  children: JSX.Element;
  img: string;
}) => (
  <VerticalLineStyled>
    <img src={img} alt="" />
    {children}
  </VerticalLineStyled>
);

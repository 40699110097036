import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import mobile from 'assets/svg/services/mobile.svg';
import web from 'assets/svg/services/web.svg';
import server from 'assets/svg/services/server.svg';
import apps from 'assets/svg/services/apps.svg';
import minModile from 'assets/svg/services/min-mobile.svg';
import minWeb from 'assets/svg/services/min-web.svg';
import minServer from 'assets/svg/services/min-server.svg';
import minApps from 'assets/svg/services/min-apps.svg';
import { Anchor } from 'modules/common/enums/anchor.enums';
import { styles } from './styles';
import { Section, SectionProps } from './components/section';
import { Lottie } from 'modules/common';
import green from 'assets/anim/services 1 green.json';
import purple from 'assets/anim/services 2 purple.json';

type Props = Omit<SectionProps, 'title' | 'description'>;

const sectionProps: Props[] = [
  {
    img: mobile,
    minImg: minModile,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
    variant: 'right',
    aspectRatio: '1/0.35',
  },
  {
    img: web,
    minImg: minWeb,
    gridColumnStart: -3,
    gridColumnEnd: -1,
    gridRowStart: 2,
    variant: 'left',
    aspectRatio: '1/0.35',
  },
  {
    img: server,
    minImg: minServer,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 3,
    variant: 'right',
    aspectRatio: '1/0.32',
  },
  {
    img: apps,
    minImg: minApps,
    gridColumnStart: -3,
    gridColumnEnd: -1,
    gridRowStart: 4,
    variant: 'left',
    aspectRatio: '1/0.4',
    pt: '15px',
  },
];

type TranslationKey = Exclude<keyof typeof translations.services, 'title'>;

export const Services = () => {
  const { t } = useTranslation();
  const isMin = useMediaQuery('@media (max-width: 1020px)');
  return (
    <Box
      sx={{
        position: 'relative',
        '@media (max-width: 1020px)': {
          marginTop: '50%',
        },
      }}
    >
      {isMin && (
        <Lottie
          animation={green}
          sx={{
            top: '-15%',
            position: 'absolute',
            right: '50%',
            transform: 'translateX(50%)',
          }}
        />
      )}
      <Typography
        variant="h1"
        align="center"
        fontSize="clamp(2rem, 5vw, 6rem)"
        sx={{ marginBottom: 4 }}
        id={Anchor.SERVICES}
      >
        {t(translations.services.title)}
      </Typography>
      <Box sx={styles.mainWrapper}>
        {sectionProps.map((el, i) => (
          <Section
            {...el}
            pt={el?.pt}
            key={el.img}
            title={t(
              translations.services[(i + 1).toString() as TranslationKey].title
            )}
            description={t(
              translations.services[(i + 1).toString() as TranslationKey]
                .description
            )}
          />
        ))}
        {!isMin && (
          <>
            <Lottie animation={green} sx={{ gridColumnStart: -2 }} />
            <Lottie
              animation={purple}
              sx={{ gridRowStart: 4, gridColumnStart: 1 }}
            />
          </>
        )}
      </Box>
      {isMin && <Lottie animation={purple} />}
    </Box>
  );
};

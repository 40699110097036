import { Box, Typography, useMediaQuery } from '@mui/material';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { Anchor } from 'modules/common/enums/anchor.enums';
import purpleBorder from 'assets/svg/about-as/purple-border.svg';
import greenBorder from 'assets/svg/about-as/green-border.svg';
import pinkBorder from 'assets/svg/about-as/pink-border.svg';
import yellowBorder from 'assets/svg/about-as/yellow-border.svg';
import purpleMin from 'assets/svg/about-as/purple-min.svg';
import greenMin from 'assets/svg/about-as/green-min.svg';
import pinkMin from 'assets/svg/about-as/pink-min.svg';
import yellowMin from 'assets/svg/about-as/yellow-min.svg';

const borders = [
  { max: purpleBorder, min: purpleMin },
  { max: greenBorder, min: greenMin },
  { max: pinkBorder, min: pinkMin },
  { max: yellowBorder, min: yellowMin },
];
type TranslationKey = keyof typeof translations.aboutUs.sections;

export const AboutUs = () => {
  const { t } = useTranslation();
  const isMin = useMediaQuery('@media (max-width: 1160px)');

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography sx={styles.title} id={Anchor.ABOUT_US}>
          {t(translations.aboutUs.title)}
        </Typography>
        <Typography sx={styles.description}>
          {t(translations.aboutUs.description)}
        </Typography>
      </Box>
      <Box sx={styles.sections}>
        {borders.map((el, index) => (
          <Box sx={styles.block} key={el.max}>
            <img
              src={!isMin ? el.max : el.min}
              alt="border"
              style={styles.border as React.CSSProperties}
            />
            <article style={styles.article as React.CSSProperties}>
              <Typography sx={styles.sectionTitle} variant="h3">
                {t(
                  translations.aboutUs.sections[
                    (index + 1).toString() as TranslationKey
                  ].title
                )}
              </Typography>
              <Typography sx={styles.sectionDescription}>
                {t(
                  translations.aboutUs.sections[
                    (index + 1).toString() as TranslationKey
                  ].description
                )}
              </Typography>
            </article>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

import { useCallback, useEffect, useState } from 'react';
import {
  BaseTextFieldProps,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { styles } from './styles';
import { theme } from 'theme';
import { ArrowButton } from 'assets/components/arrow.button';
import { Pagination } from 'swiper/modules';

export interface Props extends BaseTextFieldProps {
  list: JSX.Element[];
}

export const Slider = ({ list }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const respIsTablet = useMediaQuery('@media (max-width: 1160px)');
  const respIsMobile = useMediaQuery(theme.breakpoints.sm);
  const [step, setStep] = useState<number>(
    respIsTablet ? 2 : respIsMobile ? 1 : 3
  );

  const [leftDisabled, setLeftDisabled] = useState<boolean>(true);
  const [rightDisabled, setRightDisabled] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const setDefaultButtons = useCallback(() => {
    setLeftDisabled(!!swiperRef?.isBeginning);
    setRightDisabled(!!swiperRef?.isEnd);
  }, [swiperRef, setLeftDisabled, setRightDisabled]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideTo(currentPage + step);
    setCurrentPage(currentPage + step);
    setDefaultButtons();
  }, [swiperRef, setDefaultButtons, currentPage, step]);

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideTo(currentPage - step);
    setCurrentPage(currentPage - step);
    setDefaultButtons();
  }, [swiperRef, setDefaultButtons, currentPage, step]);

  useEffect(() => {
    if (swiperRef && !isInit) {
      swiperRef.on('slideChangeTransitionStart', setDefaultButtons);
      setIsInit(true);
    }
  }, [swiperRef, isInit, setDefaultButtons]);

  useEffect(() => {
    setStep(respIsTablet ? 2 : respIsMobile ? 1 : 3);
  }, [respIsTablet, respIsMobile]);

  return (
    <Box>
      <Box sx={styles.sliderBlock}>
        <Box sx={styles.slider}>
          <Swiper
            slidesPerView={respIsMobile ? 1 : respIsTablet ? 2 : 3}
            spaceBetween={22}
            pagination={respIsMobile}
            onSwiper={setSwiperRef}
            modules={[Pagination]}
            allowTouchMove={respIsMobile}
          >
            {list.map((item, index) => (
              <SwiperSlide key={index} style={styles.slide}>
                <Box sx={styles.slideBox}>{item}</Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      {!respIsMobile && (
        <Box sx={styles.buttonBlock}>
          <Box sx={styles.button}>
            <ArrowButton
              onClick={handleLeftClick}
              disabled={leftDisabled}
              direction="left"
            />
            <Box sx={styles.middleBox}>
              <Typography>
                {currentPage / step + 1}/{Math.ceil(list.length / step)}
              </Typography>
            </Box>
            <ArrowButton
              onClick={handleRightClick}
              disabled={rightDisabled}
              direction="right"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

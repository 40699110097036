import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Slider } from 'modules/common';
import { SliderItem } from './components';

import { translations } from 'translations/translations';

import bhfo from 'assets/svg/projects/bhfo.svg';
import density from 'assets/svg/projects/density.svg';
import neonail from 'assets/svg/projects/neonail.svg';
import puccini from 'assets/svg/projects/puccini.svg';
import thevocalcoach from 'assets/svg/projects/thevocalcoach.svg';
import thewatchbox from 'assets/svg/projects/thewatchbox.svg';
import tradersyard from 'assets/svg/projects/tradersyard.svg';
import welthee from 'assets/svg/projects/welthee.svg';
import workerbase from 'assets/svg/projects/workerbase.svg';
import * as technologiesIcons from 'assets/svg/technologies';
import { Anchor } from 'modules/common/enums/anchor.enums';
import { styles } from './styles';

interface Images {
  key: keyof typeof translations.portfolio.projects;
  mainImage: string;
  technologiesIcons: string[]; 
}

const dataImages: Images[] = [
  {
    key: 'bhfo',
    mainImage: bhfo,
    technologiesIcons: [
      technologiesIcons.azure,
      technologiesIcons.docker,
      technologiesIcons.openai,
      technologiesIcons.mongodb,
      technologiesIcons.nestjs,
    ],
  },
  {
    key: 'density',
    mainImage: density,
    technologiesIcons: [
      technologiesIcons.angular,
      technologiesIcons.graphql,
      technologiesIcons.koa,
      technologiesIcons.kubernetes,
      technologiesIcons.mongodb,
    ],
  },
  {
    key: 'neonail',
    mainImage: neonail,
    technologiesIcons: [
      technologiesIcons.nextjs,
      technologiesIcons.aws,
      technologiesIcons.kubernetes,
      technologiesIcons.nestjs,
      technologiesIcons.mongodb,
    ],
  },
  {
    key: 'puccini',
    mainImage: puccini,
    technologiesIcons: [
      technologiesIcons.nextjs,
      technologiesIcons.azure,
      technologiesIcons.upwork,
      technologiesIcons.mysql,
      technologiesIcons.graphql,
    ],
  },
  {
    key: 'thevocalcoach',
    mainImage: thevocalcoach,
    technologiesIcons: [
      technologiesIcons.react,
      technologiesIcons.aws,
      technologiesIcons.nestjs,
      technologiesIcons.mysql,
      technologiesIcons.firebase,
    ],
  },
  {
    key: 'thewatchbox',
    mainImage: thewatchbox,
    technologiesIcons: [
      technologiesIcons.nestjs,
      technologiesIcons.gcp,
      technologiesIcons.postgresql,
      technologiesIcons.koa,
      technologiesIcons.docker,
    ],
  },
  {
    key: 'tradersyard',
    mainImage: tradersyard,
    technologiesIcons: [
      technologiesIcons.vue,
      technologiesIcons.firebase,
      technologiesIcons.graphql,
      technologiesIcons.openai,
      technologiesIcons.azure,
    ],
  },
  {
    key: 'welthee',
    mainImage: welthee,
    technologiesIcons: [
      technologiesIcons.nextjs,
      technologiesIcons.etherium,
      technologiesIcons.aws,
      technologiesIcons.nestjs,
      technologiesIcons.postgresql,
    ],
  },
  {
    key: 'workerbase',
    mainImage: workerbase,
    technologiesIcons: [
      technologiesIcons.react,
      technologiesIcons.gcp,
      technologiesIcons.nestjs,
      technologiesIcons.docker,
      technologiesIcons.postgresql,
    ],
  },
];

export const Portfolio = () => {
  const { t } = useTranslation();

  const list = dataImages.map((item) => (
    <SliderItem
      {...item}
      key={item.key}
      title={t(translations.portfolio.projects[item.key].title)}
      description={t(translations.portfolio.projects[item.key].description)}
      date={t(translations.portfolio.projects[item.key].date)}
      domain={t(translations.portfolio.projects[item.key].domain)}
    />
  ));
  return (
    <Box>
      <Typography
        variant="h1"
        id={Anchor.PORTFOLIO}
        align="center"
        sx={styles.title}
      >
        {t(translations.portfolio.title)}
      </Typography>
      <Slider list={list} />
    </Box>
  );
};

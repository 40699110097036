export const styles = {
  mainWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr ',

    '@media (max-width: 1200px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },

    '@media (max-width: 1020px)': {
      gridTemplateColumns: '1fr 1fr',
      rowGap: '20px',
    },

    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    },
  },
};

import { Box } from '@mui/material';
import { VioletButton } from 'assets/components/violet-button.styled';
import { translations } from 'translations/translations';
import { AnchorButton } from 'assets/components/anchor.button';
import { useTranslation } from 'react-i18next';
import { BlackButton } from 'assets/components/black-button.styled';
import { forwardRef } from 'react';
import { Anchor } from 'modules/common/enums/anchor.enums';

export type NavbarProps = {
  menuItems: string[];
  styles: React.CSSProperties;
  isMobile?: boolean;
  closeModal?: () => void;
};

type TranslationKey = keyof typeof translations.header.menu;

export const Navbar = forwardRef(
  ({ menuItems, styles, isMobile, closeModal }: NavbarProps, _) => {
    const { t } = useTranslation();

    const handleClick = (id: string) => () => {
      if (closeModal) {
        closeModal();
      }
      const element = document.getElementById(id);
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offset = 100;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    };

    return (
      <>
        <nav style={styles}>
          {menuItems.map((item, index) => (
            <AnchorButton
              key={index}
              onClick={handleClick(item.split(' ').join('').toLowerCase())}
              variant="black"
              text={t(translations.header.menu[item as TranslationKey])}
            />
          ))}
          {isMobile ? (
            <BlackButton onClick={handleClick(Anchor.CONTACT_US)}>
              {t(translations.header.contactUs)}
            </BlackButton>
          ) : null}
        </nav>
        {isMobile ? null : (
          <Box>
            <VioletButton onClick={handleClick(Anchor.CONTACT_US)}>
              {t(translations.header.contactUs)}
            </VioletButton>
          </Box>
        )}
      </>
    );
  }
);

export const styles = {
  sliderContainer: {
    width: 'calc(100% + 44px)',
  },
  sliderBlock: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    width: '100%',
  },
  slider: {
    '.swiper-pagination-bullet-active': {
      background: '#000',
    },
    '.swiper-pagination': {
      transform: 'translateY(12px)',
    },
    '& .swiper': {
      padding: '20px !important',
    },
    '& .swiper-wrapper': {
      boxSizing: 'inherit',
    },
    '& .swiper-slide:hover': {
      borderRadius: '8px',
      boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.15)',
    },
  },
  slide: {
    height: 'auto',
  },
  slideBox: {
    height: '100%',
    display: 'flex',
    '& > .MuiBox-root': {
      margin: '0 auto',
    },
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'flex'
  },
  middleBox: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
  }
};

import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    position: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#fafafa',
    transition: '0.5s',
    zIndex: 2,
  },
  menu: {
    display: 'flex',
    gap: '20px',
  },
  menuModal: {
    backgroundColor: theme.colors.violet,
    color: theme.colors.dark,
    fontSize: theme.fontSizes.default,
    textTransform: 'none',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'absolute',
    borderRadius: '16px 0 16px 16px',
    top: '20px',
    right: '20px',
  },
  button: {
    backgroundColor: theme.colors.violet,
    color: theme.colors.dark,
    fontSize: theme.fontSizes.default,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.violet,
    },
  },
  logo: {
    padding: '6px 9px',
  },
  burger: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
};

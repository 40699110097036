import { AboutUs } from 'modules/about-us/about-us.component';
import { ContactUs } from 'modules/contact-us/contact-us.component';
import { Header } from 'modules/header';
import { Overview } from 'modules/overview';
import { Footer } from 'modules/footer';
import { styles } from 'styles';
import { Box } from '@mui/material';
import { Portfolio } from 'modules/portfolio';
import { Strategy } from 'modules/strategy';
import { Services } from 'modules/services';

function App() {
  return (
    <Box sx={styles.app}>
      <Header />
      <Box sx={styles.container}>
        <Overview />
        <AboutUs />
        <Services />
        <Portfolio />
        <Strategy />
        <ContactUs />
      </Box>
      <Footer puzzle />
    </Box>
  );
}

export default App;

import { screenSizes } from './screen-sizes.theme';

type MediaType = Record<keyof typeof screenSizes, string>;

const data: Record<string, string> = {};

for (const [key, value] of Object.entries(screenSizes)) {
  data[key] = `(max-width: ${value}px)`;
}

export const media = data as MediaType;

export const LinkedinIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7143 16.9143C20.7623 15.8434 22.1269 15.1429 23.7143 15.1429C25.3814 15.1429 26.9802 15.8051 28.159 16.9839C29.3378 18.1627 30 19.7615 30 21.4286V30H27.7143V21.4286C27.7143 20.3677 27.2929 19.3503 26.5427 18.6001C25.7926 17.85 24.7752 17.4286 23.7143 17.4286C22.6534 17.4286 21.636 17.85 20.8859 18.6001C20.1357 19.3503 19.7143 20.3677 19.7143 21.4286V30H17.4286V15.7143H19.7143V16.9143ZM11.7143 13.4286C11.2596 13.4286 10.8236 13.248 10.5021 12.9265C10.1806 12.605 10 12.1689 10 11.7143C10 11.2596 10.1806 10.8236 10.5021 10.5021C10.8236 10.1806 11.2596 10 11.7143 10C12.1689 10 12.605 10.1806 12.9265 10.5021C13.248 10.8236 13.4286 11.2596 13.4286 11.7143C13.4286 12.1689 13.248 12.605 12.9265 12.9265C12.605 13.248 12.1689 13.4286 11.7143 13.4286ZM10.5714 15.7143H12.8571V30H10.5714V15.7143Z"
      fill="#C1B5FF"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#C1B5FF" />
  </svg>
);

import { Box } from '@mui/material';
import React from 'react';
import { default as ReactLottie, Options } from 'react-lottie';

interface Props {
  animation: any;
  height?: string;
  width?: string;
  sx?: React.CSSProperties;
}

export const Lottie = ({ animation, height, width, sx }: Props) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box sx={sx}>
      <ReactLottie options={defaultOptions} height={height} width={width} />
    </Box>
  );
};

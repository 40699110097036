import { breakpoints } from './breakpoints.theme';
import { colors } from './colors.theme';
import { fontSizes } from './font-sizes.theme';
import { fontWeights } from './font-weights.theme';
import { fonts } from './fonts.theme';
import { media } from './media.theme';

export const theme = {
  colors,
  fonts,
  media,
  fontSizes,
  fontWeights,
  breakpoints,
};

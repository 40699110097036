interface LogoProps {
  type?: 'dark' | 'light';
}

export const LogoIcon = ({ type = 'light' }: LogoProps) => {
  const isDark = type === 'dark';

  const color = isDark ? '#C1B5FF' : '#151521';
  const width = isDark ? '54' : '42';
  const height = isDark ? '62' : '48';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3606 17.5879C40.0187 17.7856 39.8077 18.154 39.8077 18.5529V33.3259C39.8077 34.326 39.2799 35.2497 38.4241 35.7474L22.3701 45.0835C21.5215 45.577 20.4783 45.577 19.6296 45.0838L3.56596 35.7473C2.70985 35.2497 2.18182 34.3258 2.18182 33.3255V14.6737C2.18182 13.6738 2.70941 12.7502 3.56496 12.2525L19.6288 2.90679C20.4779 2.41279 21.5219 2.41285 22.371 2.90696L34.9144 10.2066C35.2489 10.4012 35.6603 10.4014 35.9949 10.2069C36.7221 9.78422 36.7224 8.72101 35.9953 8.298L22.3709 0.370534C21.5218 -0.123464 20.478 -0.123514 19.6289 0.370401L1.38324 10.9844C0.527633 11.4821 0 12.4057 0 13.4056V34.5943C0 35.5942 0.527634 36.5178 1.38325 37.0156L19.6291 47.6296C20.478 48.1235 21.5218 48.1235 22.3708 47.6296L40.6168 37.0156C41.4724 36.5178 42 35.5942 42 34.5943V18.5529C42 17.7001 41.0913 17.1652 40.3606 17.5879Z"
        fill="#C1B5FF"
      />
      <path
        d="M15.4986 33.7708C15.4986 34.5417 14.8821 35.1666 14.1216 35.1666H13.7705C13.0099 35.1666 12.3934 34.5417 12.3934 33.7708V25.8248C12.3934 25.0539 13.0099 24.429 13.7705 24.429H22.744C23.7233 24.4178 24.6589 24.0094 25.3485 23.2923C26.0381 22.5753 26.4262 21.607 26.4288 20.5971C26.4344 20.0978 26.341 19.6024 26.1541 19.1418C25.967 18.6809 25.6908 18.2646 25.3419 17.9181C25.0044 17.5604 24.6004 17.2768 24.154 17.0842C23.7076 16.8915 23.2279 16.794 22.744 16.7972H13.7705C13.0099 16.7972 12.3934 16.1723 12.3934 15.4014V14.927C12.3934 14.1561 13.0099 13.5312 13.7705 13.5312H22.7543C24.5682 13.5424 26.3048 14.2905 27.5875 15.6131C28.8701 16.9358 29.5955 18.7266 29.6063 20.5971C29.6174 22.029 29.1941 23.4284 28.3954 24.5997C27.6604 25.7203 26.6245 26.5956 25.4145 27.1186C24.2162 27.5105 22.9659 27.7051 21.7089 27.6951H16.8757C16.1151 27.6951 15.4986 28.32 15.4986 29.0909V33.7708Z"
        fill={color}
      />
      <path
        d="M28.4662 32.3936C28.9775 33.324 28.3139 34.4687 27.2632 34.4687H26.9179C26.4182 34.4687 25.9577 34.1943 25.7147 33.7516L24.4232 31.3992C23.953 30.5427 24.5269 29.467 25.4636 29.2238C26.0907 29.061 26.7701 29.3075 27.0851 29.8807L28.4662 32.3936Z"
        fill={color}
      />
      <path
        d="M38.2757 15.4053C39.2647 15.9815 40.5305 15.6377 41.1028 14.6374C41.6751 13.6371 41.3373 12.3591 40.3482 11.7829C39.3592 11.2067 38.0934 11.5505 37.5211 12.5508C36.9488 13.551 37.2866 14.8291 38.2757 15.4053Z"
        fill={color}
      />
    </svg>
  );
};

export const GithubIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0242 10.3295C15.7752 10.5836 16.4896 10.935 17.1492 11.3745C18.0808 11.1366 19.0387 11.0171 20.0002 11.0188C20.9932 11.0188 21.9512 11.1427 22.8492 11.3735C23.5086 10.9344 24.2227 10.5834 24.9732 10.3295C25.6702 10.0927 26.6632 9.70913 27.2532 10.3615C27.6532 10.805 27.7532 11.5483 27.8242 12.1157C27.9042 12.7491 27.9232 13.5743 27.7132 14.3935C28.5162 15.4295 29.0002 16.6652 29.0002 18.0119C29.0002 20.0519 27.8942 21.8231 26.2572 23.0499C25.4694 23.6328 24.5956 24.0899 23.6672 24.4046C23.8812 24.8941 24.0002 25.4355 24.0002 26.004V29.001C24.0002 29.2659 23.8949 29.52 23.7073 29.7074C23.5198 29.8947 23.2654 30 23.0002 30H17.0002C16.735 30 16.4807 29.8947 16.2931 29.7074C16.1056 29.52 16.0002 29.2659 16.0002 29.001V28.011C15.0452 28.1279 14.2442 28.024 13.5632 27.7352C12.8512 27.4335 12.3552 26.966 11.9822 26.5185C11.6282 26.0949 11.2422 25.1398 10.6842 24.954C10.5596 24.9125 10.4444 24.847 10.3451 24.761C10.2459 24.6751 10.1645 24.5705 10.1057 24.4531C9.98703 24.2161 9.96742 23.9417 10.0512 23.6903C10.135 23.4388 10.3154 23.2309 10.5526 23.1123C10.7899 22.9937 11.0645 22.9742 11.3162 23.0579C11.9822 23.2797 12.4162 23.7592 12.7132 24.1448C13.1932 24.7642 13.5832 25.5734 14.3432 25.8961C14.6562 26.0289 15.1152 26.1159 15.8332 26.0179L16.0002 25.984C16.0021 25.4403 16.1155 24.9028 16.3332 24.4046C15.4048 24.0899 14.531 23.6329 13.7432 23.0499C12.1062 21.8231 11.0002 20.0529 11.0002 18.0119C11.0002 16.6672 11.4832 15.4324 12.2842 14.3975C12.0742 13.5783 12.0922 12.7511 12.1722 12.1167L12.1772 12.0788C12.2502 11.4974 12.3352 10.813 12.7432 10.3615C13.3332 9.70913 14.3272 10.0937 15.0232 10.3305L15.0242 10.3295Z"
      fill="#C1B5FF"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#C1B5FF" />
  </svg>
);

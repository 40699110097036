import { Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export type ArrowButtonProps = {
  onClick(event: React.MouseEvent): void;
  disabled: boolean;
  direction: 'left' | 'right';
};

export const ArrowButton = ({
  onClick,
  disabled,
  direction,
}: ArrowButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: '#000',
        borderRadius:
          direction === 'right' ? '0px 8px 8px 0px' : '8px 0px 0px 8px',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#282727',
        },
        '&:disabled': {
          color: '#ffffff90',
        },
      }}
    >
      {direction === 'right' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
    </Button>
  );
};

import { Box, Button, Modal, useMediaQuery } from '@mui/material';

import { LogoIcon } from 'assets/components/logo.icon';
import { translations } from 'translations/translations';

import { styles } from './styles';
import { theme } from 'theme';
import { BurgerIcon } from 'assets/components/burger.icon';
import { useEffect, useRef, useState } from 'react';
import { Navbar } from './components/header.navbar';

export const Header = () => {
  const sm = useMediaQuery(theme.media.sm);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = Object.keys(translations.header.menu);

  const handleOpenMenu = () => {
    setIsModalOpen(true);
  };

  const handleClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const [show, setShow] = useState(true);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop.current) {
        setShow(false);
      } else {
        setShow(true);
      }

      lastScrollTop.current = scrollTop;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={styles.root} top={show ? 0 : '-100px'}>
      <Box sx={styles.logo}>
        <LogoIcon />
      </Box>
      {sm ? (
        <Button onClick={handleOpenMenu} sx={styles.burger}>
          <BurgerIcon />
        </Button>
      ) : (
        <Navbar menuItems={menuItems} styles={styles.menu} />
      )}
      <Modal open={isModalOpen} onClose={handleClose}>
        <Navbar
          isMobile
          menuItems={menuItems}
          styles={styles.menuModal as React.CSSProperties}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
    </Box>
  );
};

export const colors = {
  black: '#000000',
  darker: '#181818',
  dark: '#151521',
  darkBlue: '#1E1E2F',
  lightBlack: '#151521',
  lightViolet: '#D5D0F0',
  violet: '#C1B5FF',
  lightGrey: '#DEE2E6',
  darkGrey: '#B3B3B3',
  grey: '#505059',
  white: '#FFFFFF',
  yellow: '#FFC02F',
  green: '#2DCA8F',
  pink: '#FFB7D7',
};

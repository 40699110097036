import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import { PortfolioLabel } from './portfolio.label';

interface Props {
  mainImage: string;
  technologiesIcons: string[];
  title: string;
  description: string;
  date: string;
  domain: string;
}

export const SliderItem = ({
  mainImage,
  technologiesIcons,
  title,
  description,
  date,
  domain,
}: Props) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainImage}>
        <img src={mainImage} alt="main" />
      </Box>
      <Box sx={styles.titleBlock}>
        <Typography sx={styles.title}>{title}</Typography>
        {/* TODO: copy icon */}
      </Box>
      <Box sx={styles.labels}>
        <PortfolioLabel type="date" content={date} />
        <PortfolioLabel type="domain" content={domain} />
      </Box>
      <Box sx={styles.technologies}>
        {technologiesIcons.map((el) => (
          <img src={el} alt="technology" key={el} />
        ))}
      </Box>
      <Box>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

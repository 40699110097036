import { theme } from 'theme';

export const styles = {
  img: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
  },
  box: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '0.7fr 1fr 1fr 1fr 1fr',
    aspectRatio: '1/0.4',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  title: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'clamp(2rem, 3vw, 4rem)',
    boxSizing: 'border-box',
  },
  description: {
    textAlign: 'center',
    padding: '0 30px',
    fontSize: 'clamp(0.8rem, 1.1vw, 1.2rem)',
    [theme.breakpoints.sm]: {
      fontSize: 'clamp(0.9rem, 1.3vw, 1.5rem)',
    },
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

import { Typography } from '@mui/material';

export type ArticleProps = {
  title: string;
  description: string;
};

export const Article = ({ title, description }: ArticleProps) => {
  return (
    <article>
      <Typography
        variant="h4"
        sx={{ marginBottom: 2 }}
        fontSize={'clamp(1.2rem, 2vw, 3rem)'}
      >
        {title}
      </Typography>
      <Typography fontSize={'clamp(0.9rem, 1.5vw, 1.5rem)'}>
        {description}
      </Typography>
    </article>
  );
};

import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.sm]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',

      '& p': {
        width: '100%',
        textAlign: 'center',
      }
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'clamp(2rem, 5vw, 6rem)',
  },
  description: {
    fontSize: 'clamp(1rem, 1.5vw, 3rem)',
    width: '40%',
  },
  sections: {
    display: 'grid',
    gap: '24px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media (max-width: 1160px)': {
      padding: '0 10vw',
      gap: '5vw',
      gridTemplateColumns: '0.5fr 0.5fr',
    },

    '@media (max-width: 700px)': {
      padding: '0',
    },

    [theme.breakpoints.sm]: {
      gridTemplateColumns: '1fr',
    }
  },
  block: {
    position: 'relative',
    aspectRatio: '1 / 0.85',
    '@media (max-width: 1160px)': {
      aspectRatio: '1 / 0.63',
    }
  },
  border: { 
    position: 'absolute', 
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  sectionTitle: {
    fontSize: 'clamp(1.5rem, 2vw, 3rem)',
    [theme.breakpoints.sm]: {
      fontSize: 'clamp(1.8rem, 2.3vw, 5rem)',
    },
  },
  sectionDescription: {
    fontSize: 'clamp(0.8rem, 1.1vw, 1.2rem)',
    textAlign: 'center',
    [theme.breakpoints.sm]: {
      fontSize: 'clamp(0.9rem, 1.3vw, 1.5rem)',
    },
  },
  article: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '5%',
    boxSizing: 'border-box',
  }
};

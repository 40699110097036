import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    backgroundColor: theme.colors.lightViolet,
    padding: '100px',
    borderRadius: '24px',
    justifyContent: 'space-around',
    gap: '100px',

    [theme.breakpoints.md]: {
      flexDirection: 'column',
      gap: '20px',
      padding: '20px',
    },
  },
  container: {
    display: 'flex',
  },
  content: {
    flex: '3',
  },
  title: {
    fontSize: theme.fontSizes.h2,

    [theme.breakpoints.md]: {
      textAlign: 'center',
    },
  },
  description: {
    fontSize: theme.fontSizes.t1,

    [theme.breakpoints.md]: {
      textAlign: 'center',
      fontSize: theme.fontSizes.t2,
    },
  },
  mail: {
    fontSize: theme.fontSizes.t1,
    position: 'relative',
    zIndex: '1',

    [theme.breakpoints.md]: {
      textAlign: 'center',
      fontSize: theme.fontSizes.t2,
    },
  },
  form: {
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
  },
  input: {
    border: `1px solid ${theme.colors.lightGrey}`,
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    padding: '4px 10px',
    width: 'min(100%, 300px)',
    fontSize: theme.fontSizes.default,
    '::placeholder': {
      color: `${theme.colors.black} !important`,
    },
  },
  bigInput: {
    border: `1px solid ${theme.colors.lightGrey}`,
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    padding: '4px 10px',
    width: 'min(100%, 300px)',
    boxSizing: 'border-box',
    fontSize: theme.fontSizes.default,
    resize: 'none',
    position: 'relative',
    zIndex: '1',
  },
  button: {
    backgroundColor: theme.colors.dark,
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: theme.colors.dark,
    },
  },
  email: {
    textDecoration: 'none',
    color: theme.colors.dark,
  },
  snackbar: {
    top: 'auto',
    bottom: '20px',
    width: 300,
    height: '50px',
  },
  error: {
    color: '#bf1650',
    '&::before': {
      content: "'⚠ '",
    },
  },
};

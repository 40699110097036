import { theme } from 'theme';

export const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    gap: '70px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '100px',
    marginTop: '64px',
    padding: '10px 60px',

    [theme.breakpoints.md]: {
      padding: '10px 15px',
    },
  },
};

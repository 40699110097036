import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.darker,
    padding: '20px',
    position: 'relative',

    [theme.breakpoints.md]: {
      flexDirection: 'column',
      gap: '15px',
    },
  },
  menu: {
    display: 'flex',
    gap: '20px',
    color: theme.colors.white,

    [theme.breakpoints.md]: {
      flexDirection: 'column',
      gap: '15px',
    },
  },
  button: {
    backgroundColor: theme.colors.violet,
    color: theme.colors.dark,
    fontSize: theme.fontSizes.b,
  },
  logo: {
    padding: '6px 9px',
  },
  rigths: {
    color: theme.colors.darkGrey,
    maxWidth: '170px',
  },
  email: {
    color: theme.colors.white,
    textDecoration: 'none',
  },
  privacyPolicy: {
    color: theme.colors.darkGrey,
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    position: 'relative',
    zIndex: '1',
  },
  iconsBox: {
    display: 'flex',
    gap: '10px',
  },
  mobileRights: {
    // margin: '20px',
    width: '100%',
    display: 'flex',
    gap: '0px',
    justifyContent: 'space-between',
  },
  puzzle: {
    position: 'absolute',
    bottom: '80%',
    zIndex: 0,
    '@media (max-width: 900px)': {
      bottom: '93%',
      right: '50%',
      transform: 'translateX(50%)',
    },
    '@media (max-width: 470px)': { bottom: '95%' },
    right: 0,
  }
};

import { Typography } from '@mui/material';
import { styles } from './styles';
import { theme } from 'theme';

export type PortfolioLabelProps = {
  type: 'date' | 'domain';
  content: string;
};

const getColor = (content: string) =>
  ({
    'e-commerce': theme.colors.yellow,
    business: theme.colors.green,
    blockchain: theme.colors.pink,
    startup: theme.colors.yellow,
  }[content] || theme.colors.yellow);

export const PortfolioLabel = ({ type, content }: PortfolioLabelProps) => {
  return (
    <Typography
      sx={{
        ...styles.label,
        backgroundColor:
          type === 'date' ? theme.colors.violet : getColor(content),
      }}
    >
      {content}
    </Typography>
  );
};

export const UpworkIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4993 13.4375C25.2522 13.4593 24.0507 13.907 23.0969 14.7053C22.143 15.5036 21.4949 16.6039 21.2616 17.8211C20.0905 16.0129 19.1733 14.0545 18.5351 12H15.8326V19.1279C15.8326 19.8021 15.563 20.4488 15.0831 20.9255C14.6031 21.4023 13.9522 21.6702 13.2735 21.6702C12.5948 21.6702 11.9439 21.4023 11.464 20.9255C10.9841 20.4488 10.7145 19.8021 10.7145 19.1279V12L8 12.0594V19.1873C8.00793 20.5736 8.56988 21.9 9.56222 22.8747C10.5546 23.8494 11.896 24.3926 13.2915 24.3847C14.6869 24.3768 16.0221 23.8186 17.0032 22.8327C17.9844 21.8469 18.5311 20.5142 18.5232 19.1279V17.9399C19.0969 19.0579 19.7566 20.1302 20.4963 21.1475L18.846 29H21.6203L22.8161 23.2858C23.9244 23.9934 25.2179 24.3611 26.5351 24.3431C27.9845 24.3431 29.3745 23.7711 30.3994 22.753C31.4242 21.7348 32 20.3539 32 18.914C32 17.4742 31.4242 16.0933 30.3994 15.0751C29.3745 14.057 27.9845 13.485 26.5351 13.485L26.4993 13.4375ZM26.4993 21.5514C25.3756 21.5153 24.2987 21.0958 23.4499 20.3634L23.725 19.2823V19.2229C23.9163 18.0349 24.5142 16.1342 26.5351 16.1342C26.8895 16.131 27.2409 16.1975 27.5694 16.3297C27.8978 16.4618 28.1967 16.6572 28.4489 16.9044C28.7011 17.1517 28.9017 17.446 29.0391 17.7705C29.1765 18.095 29.2481 18.4432 29.2496 18.7952C29.224 19.508 28.9315 20.1856 28.4294 20.6954C27.9274 21.2051 27.2518 21.5104 26.5351 21.5514H26.4993Z"
      fill="#C1B5FF"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#C1B5FF" />
  </svg>
);

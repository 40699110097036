import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { VioletButton } from 'assets/components/violet-button.styled';
import { OutlinedButton } from 'assets/components/outlined-button.styled';
import people from 'assets/anim/mens 1 block.json';
import arrow from 'assets/anim/arrow.json';
import { Lottie } from 'modules/common';
import { theme } from 'theme';
import { Anchor } from 'modules/common/enums/anchor.enums';

type TranslationKey = keyof typeof translations.overview.metrics;

export const Overview = () => {
  const { t } = useTranslation();
  const metricItems = Object.keys(translations.overview.metrics);
  const sm = useMediaQuery(theme.media.sm);

  return (
    <Stack mb="10%">
      <Box sx={styles.root}>
        <Box sx={styles.overview}>
          <Box sx={styles.container}>
            <Typography style={styles.title}>
              {t(translations.overview.title)}
            </Typography>
            <Typography sx={styles.subtitle}>
              {t(translations.overview.subtitle)}
            </Typography>
            <Box sx={styles.buttons}>
              <VioletButton href={`#${Anchor.CONTACT_US}`}>
                {t(translations.overview.buttons.buildProject)}
              </VioletButton>
              <OutlinedButton href={`#${Anchor.PORTFOLIO}`}>
                {t(translations.overview.buttons.seePortfolio)}
              </OutlinedButton>
            </Box>
          </Box>

          <Box sx={styles.metrics}>
            {metricItems.map((el, index) => (
              <Box sx={styles.metricBox} key={index}>
                <Typography sx={styles.metricTitle}>
                  {t(translations.overview.metrics[el as TranslationKey].title)}
                </Typography>
                <Typography sx={styles.metricDescription}>
                  {t(
                    translations.overview.metrics[el as TranslationKey]
                      .description
                  )}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {!sm && (
          <Box
            sx={{
              position: 'relative',
              top: '-5px',
            }}
          >
            <Lottie
              animation={people}
              sx={{ aspectRatio: '1/1', width: '100%' }}
            />
            <Lottie
              animation={arrow}
              height="100%"
              width="100%"
              sx={{
                display: 'flex',
                maxWidth: '30%',
                maxHeight: '30%',
                position: 'absolute',
                bottom: '-30%',
                left: 0,
              }}
            />
          </Box>
        )}
      </Box>
      {sm && (
        <Box>
          <Lottie animation={people} height="70%" width="70%" />
        </Box>
      )}
    </Stack>
  );
};

import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'min-content',
    alignItems: 'center',
    // TODO: add gap
  },
  overview: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '@media (max-width: 600px)': {
      alignItems: 'center',
    },
  },
  title: {
    fontSize: 'clamp(2rem, 5vw, 6rem)',
  },
  subtitle: {
    display: 'flex',
    fontSize: 'clamp(18px, 2.5vw, 1.7rem)',
    '@media (max-width: 425px)': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buttons: {
    display: 'flex',
    gap: '16px',
    // TODO: add gap
  },
  metricBox: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.sm]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  metricTitle: {
    fontSize: theme.fontSizes.h2,
  },
  metricDescription: {
    fontSize: theme.fontSizes.t2,
  },
  metrics: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
};

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { Anchor } from 'modules/common/enums/anchor.enums';
import { Article } from './components/article';
import { HorizontalLine } from './components/horizontal.line';
import { VerticalLine } from './components/vertical.line';
import green from 'assets/svg/strategy/green.svg';
import yellow from 'assets/svg/strategy/yellow.svg';
import pink from 'assets/svg/strategy/pink.svg';
import { Lottie } from 'modules/common';
import people from 'assets/anim/strategy 1 people .json';
import computer from 'assets/anim/strategy 2 green.json';
import { theme } from 'theme';

export const Strategy = () => {
  const { t } = useTranslation();
  const sm = useMediaQuery(theme.media.sm);

  return (
    <Box sx={styles.mainWrapper}>
      {sm && (
        <Box>
          <Lottie animation={people} />
        </Box>
      )}
      <Typography
        variant="h1"
        id={Anchor.STRATEGY}
        align="center"
        sx={styles.title}
      >
        {t(translations.strategy.title)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Stack>
          <VerticalLine img={green}>
            <Article
              title={t(translations.strategy['1'].title)}
              description={t(translations.strategy['1'].description)}
            />
          </VerticalLine>
          <HorizontalLine>
            <Article
              title={t(translations.strategy['2'].title)}
              description={t(translations.strategy['2'].description)}
            />
          </HorizontalLine>
        </Stack>
        {!sm && (
          <Box>
            <Lottie animation={people} />
          </Box>
        )}
      </Box>
      <Box sx={styles.wrapper}>
        <Box sx={styles.computer}>
          <Lottie animation={computer} />
        </Box>
        <Stack height={'100%'} sx={{ position: 'relative' }}>
          <VerticalLine img={yellow}>
            <Article
              title={t(translations.strategy['3'].title)}
              description={t(translations.strategy['3'].description)}
            />
          </VerticalLine>
          <VerticalLine img={pink}>
            <Article
              title={t(translations.strategy['4'].title)}
              description={t(translations.strategy['4'].description)}
            />
          </VerticalLine>
          <Box sx={styles.line}></Box>
        </Stack>
      </Box>
    </Box>
  );
};

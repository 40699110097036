import {
  Box,
  CircularProgress,
  Input,
  Link,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styles } from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { config } from 'config';
import { theme } from 'theme';
import MuiAlert from '@mui/material/Alert';
import { BlackButton } from 'assets/components/black-button.styled';
import { Anchor } from 'modules/common/enums/anchor.enums';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const ContactUs = () => {
  const { t } = useTranslation();

  const md = useMediaQuery(theme.media.md);

  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t(translations.contactUs.validation.nameRequired)),
    email: yup
      .string()
      .email()
      .required(t(translations.contactUs.validation.emailRequired)),
    message: yup
      .string()
      .required(t(translations.contactUs.validation.coverLetterRequired)),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
  });

  const handleApply = async (data: {
    name: string;
    email: string;
    message: string;
  }) => {
    try {
      setIsLoading(true);
      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATEID!,
        data,
        process.env.REACT_APP_EMAILJS_USERID!
      );
      setSnackbarMessage(response.text);
      setIsError(false);
      setOpen(true);
    } catch (error) {
      setIsError(true);
      setSnackbarMessage('Something went wrong');
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={styles.root} id={Anchor.CONTACT_US}>
      <Box sx={styles.content}>
        <Typography sx={styles.title}>
          {t(translations.contactUs.title)}
        </Typography>
        <Typography sx={styles.description}>
          {t(translations.contactUs.description)}
        </Typography>
        {!md && (
          <Typography sx={styles.mail}>
            <Trans
              t={t}
              defaults={translations.contactUs.mail}
              values={{ email: config.email }}
              components={{
                a: (
                  <Link style={styles.email} href={`mailto:${config.email}`} />
                ),
              }}
            />
          </Typography>
        )}
      </Box>

      <Box
        sx={styles.form}
        component="form"
        onSubmit={handleSubmit(handleApply)}
      >
        <Stack width="100%" alignItems="center">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t(translations.contactUs.placeholers.name)}
                disableUnderline
                sx={styles.input}
              />
            )}
          />
          {errors.name && (
            <Typography sx={styles.error}>{errors.name?.message}</Typography>
          )}
        </Stack>
        <Stack width="100%" alignItems="center">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t(translations.contactUs.placeholers.email)}
                disableUnderline
                sx={styles.input}
              />
            )}
          />
          {errors.email && (
            <Typography sx={styles.error}>{errors.email?.message}</Typography>
          )}
        </Stack>
        <Stack width="100%" alignItems="center">
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                className="constact-us-textarea"
                style={styles.bigInput as any}
                placeholder={t(translations.contactUs.placeholers.coverLetter)}
                minRows={6}
                maxRows={6}
              />
            )}
          />
          {errors.message && (
            <Typography sx={styles.error}>{errors.message?.message}</Typography>
          )}
        </Stack>

        <BlackButton type="submit" sx={{ minWidth: '135px' }}>
          {isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            t(translations.contactUs.build)
          )}
        </BlackButton>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
        sx={styles.snackbar}
      >
        <MuiAlert severity={isError ? 'error' : 'success'}>
          <Typography>{snackbarMessage}</Typography>
        </MuiAlert>
      </Snackbar>

      {md && (
        <Box>
          <Typography sx={styles.mail}>
            <Trans
              t={t}
              defaults={translations.contactUs.mail}
              values={{ email: config.email }}
              components={{
                a: (
                  <Link style={styles.email} href={`mailto:${config.email}`} />
                ),
              }}
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
